import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content-body"
};
const _hoisted_2 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Head = _resolveComponent("Head");

  const _component_router_link = _resolveComponent("router-link");

  const _component_el_header = _resolveComponent("el-header");

  const _component_RegionShow = _resolveComponent("RegionShow");

  const _component_el_main = _resolveComponent("el-main");

  const _component_FooterShow = _resolveComponent("FooterShow");

  const _component_el_footer = _resolveComponent("el-footer");

  const _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, null, {
    default: _withCtx(() => [_createElementVNode("title", null, _toDisplayString($data.title), 1)]),
    _: 1
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_el_header, {
      class: "header"
    }, {
      default: _withCtx(() => [_createVNode(_component_router_link, {
        to: "/"
      }, {
        default: _withCtx(() => [_createElementVNode("h1", null, _toDisplayString($data.title), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_main, null, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_component_RegionShow, {
        key: $options.api_url,
        data_url: $options.api_url,
        info_url: $data.info_url,
        type_latest: $options.type_latest
      }, null, 8, ["data_url", "info_url", "type_latest"]))]),
      _: 1
    }), _createVNode(_component_el_footer, {
      class: "footer"
    }, {
      default: _withCtx(() => [_createVNode(_component_FooterShow)]),
      _: 1
    })]),
    _: 1
  })])])], 64);
}