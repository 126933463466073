import axios from "axios";
import { ElNotification } from 'element-plus';
import 'element-plus/es/components/notification/style/css'

import debounce from 'lodash.debounce';
import localforage from 'localforage';
export default {
  name: "RegionShow",
  props: {
    data_url: String,
    info_url: String,
    type_latest: Boolean
  },
  computed: {
    data_name() {
      return this.data_url.split("/").pop().split(".").shift();
    },

    data_name_timestamp() {
      return this.data_name + "_timestamp";
    }

  },

  data() {
    return {
      api_url_list: JSON.parse(localStorage.getItem("api_url_list") || "[]"),
      api_url: localStorage.getItem("api_url") || "",
      gh_proxy_list: JSON.parse(localStorage.getItem("gh_proxy_list") || "[]"),
      selected_tab: '',
      is_scrolling: false,
      section_observer: null,
      section_selector: ["#high-risk", "#middle-risk", "#low-risk", '.filter-tree', '.header'],
      raw: null,
      ok: false,
      err: false,
      err_msg: "",
      info: {
        raw: null,
        visible: false,
        download_visible: false,
        ok: false,
        err: false,
        err_msg: "",
        table: null
      },
      history: {
        pre: null,
        next: null,
        save_time: null
      },
      high: {
        key: 0,
        display: true,
        tree: null,
        count: '-',
        province_id_list: [],
        city_id_list: [],
        county_id_list: [],
        default_id_list: [],
        expand_all: false,
        expand_all_button: "展开"
      },
      middle: {
        key: 0,
        display: false,
        tree: null,
        count: '-',
        province_id_list: [],
        city_id_list: [],
        county_id_list: [],
        default_id_list: [],
        expand_all: false,
        expand_all_button: "展开"
      },
      low: {
        key: 0,
        display: true,
        tree: null,
        count: '-',
        province_id_list: [],
        city_id_list: [],
        county_id_list: [],
        default_id_list: [],
        expand_all: false,
        expand_all_button: "展开"
      },
      tree_props: {
        label: 'label',
        children: 'children',
        class: 'tree-node'
      },
      filter_text: "",
      filter_history: JSON.parse(localStorage.getItem("filter_history") || '["北京市", "上海市", "江苏省"]'),
      dark_mode: false,
      loading_icon: false
    };
  },

  async mounted() {
    if (process.env.NODE_ENV === 'development') {
      window.vue = this;
    }

    let that = this;

    if (!this.api_url) {
      this.api_url = this.api_url_list[0];
      localStorage.setItem("api_url", this.api_url);
    }

    if (this.type_latest) {
      if (!(await localforage.getItem(this.data_name_timestamp)) || !(await localforage.getItem(this.data_name))) {
        await this.fetch_latest(false);
      } else {
        await this.fetch_latest(true);
      }
    } else {
      await this.fetch_history();
      this.fetch_data(this.info_url, null, true).then(response => {
        that.info.raw = response;
        const file_name = this.$route.params.fileName;
        const history_info = this.get_current_history_info(file_name);
        this.history.pre = history_info.pre;
        this.history.next = history_info.next;
        this.history.save_time = history_info.save_time;
      });
    }

    let media = window.matchMedia('(prefers-color-scheme: dark)');
    media.addEventListener('change', this.handle_color_scheme_change);
    this.handle_color_scheme_change(media);
    let observe_interval = setInterval(() => {
      if (!this.loading_icon) {
        this.observe_sections();
        clearInterval(observe_interval);
      }
    }, 100);

    if (this.$route.hash && this.section_selector.indexOf(this.$route.hash) !== -1) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      let interval = setInterval(() => {
        if (!this.loading_icon) {
          this.scroll_to(this.$route.hash);
          this.selected_tab = `#${this.$route.hash}`;
          clearInterval(interval);
        }
      }, 100);
    }
  },

  methods: {
    async fetch_data(url, api_index = null, force_local = false, force_fetch = false, loop_times = 0) {
      let name = url.split(".").shift();
      let name_timestamp = name + "_timestamp";
      let name_local = await localforage.getItem(name);

      if (force_local && !!name_local) {
        return name_local;
      }

      if (!force_fetch && new Date().getTime() - (await localforage.getItem(name_timestamp)) < 5 * 60 * 1000) {
        return name_local;
      }

      let api_url = this.api_url;

      if (api_index !== null) {
        if (api_index < 0 || api_index >= this.api_url_list.length) {
          api_index = 0;
        }

        api_url = this.api_url_list[api_index];
        localStorage.setItem("api_url", api_url);
      }

      let new_url = api_url + url;
      let timeout_time = 3000;
      let CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, timeout_time);
      let that = this;

      try {
        let res = await axios.get(new_url, {
          cancelToken: source.token
        });
        clearTimeout(timeout);
        let raw = res.data;

        try {
          await localforage.setItem(name, raw);
          await localforage.setItem(name_timestamp, new Date().getTime());
        } catch (e) {
          console.log(e);
          localforage.keys().then(keys => {
            keys.forEach(key => {
              if (key.indexOf("api") === -1 && key.indexOf("latest") === -1 && key.indexOf("info") === -1) {
                localforage.removeItem(key);
              }
            });
          });
        }

        return Promise.resolve(raw);
      } catch (error) {
        clearTimeout(timeout);
        console.log(new_url, error);
        console.log(error.response);

        if (api_index === null) {
          api_index = that.api_url_list.indexOf(api_url);
        }

        api_index++;
        loop_times++;
        const max_loop_times = that.api_url_list.length < 10 ? that.api_url_list.length : 10;

        if (error.response && error.response.status === 404 && loop_times > 5 || loop_times > max_loop_times) {
          return Promise.reject(error);
        }

        return that.fetch_data(url, api_index, force_local, force_fetch, loop_times);
      }
    },

    async fetch_latest(check_local = false) {
      if (check_local) {
        this.raw = await localforage.getItem(this.data_name);
        this.high_init().then();
        this.middle_init().then();
        this.low_init().then();
        this.ok = true;

        if (new Date().getTime() - (await localforage.getItem(this.data_name_timestamp)) < 5 * 60 * 1000) {
          return;
        } else {
          this.loading_icon = true;
        }
      } else {
        this.loading_icon = true;
      }

      let url = this.data_url + "?t=" + new Date().getTime();
      this.fetch_data(url, null, false, true).then(async data => {
        let msg;
        let update_required = true;

        if (this.ok) {
          if (this.raw.data["end_update_time"] !== data["data"]["end_update_time"]) {
            msg = "数据更新成功";
          } else {
            msg = "已是最新数据";
            update_required = false;
          }
        } else {
          msg = "数据加载成功";
        }

        ElNotification.success({
          message: msg,
          duration: 2500,
          position: 'bottom-right',
          showClose: false,
          customClass: 'notification-item'
        });

        if (update_required) {
          this.raw = data;
          this.high_init().then();
          this.middle_init().then();
          this.low_init().then();
          this.ok = true;
        }

        this.loading_icon = false;
      }).catch(error => {
        console.log(error);

        if (this.ok) {
          let msg = "数据更新失败<br>已显示缓存数据";
          ElNotification.info({
            message: msg,
            duration: 2500,
            position: 'bottom-right',
            showClose: false,
            customClass: 'notification-item',
            dangerouslyUseHTMLString: true
          });
        } else {
          this.err_msg = error;
          this.err = true;
        }

        this.loading_icon = false;
      });
    },

    fetch_info() {
      this.info.visible = true;
      this.info.err = false;
      let that = this;
      this.fetch_data(this.info_url).then(response => {
        that.info.raw = response;
        let file_list = response["file_list"];
        let table = [];
        let id_count = 0;

        for (let i = file_list.length - 1; i >= 0; i--) {
          let item = file_list[i];
          let file = item["file_name"].replace(".json", "");
          let update_timestamp = new Date(item["update_time"] * 1000);
          let day = update_timestamp.toLocaleString('zh-CN', {
            timeZone: 'Asia/Shanghai',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          });
          let update_time = update_timestamp.toLocaleString('zh-CN', {
            timeZone: 'Asia/Shanghai',
            hour12: false,
            hour: '2-digit'
          });
          day = day.replace(/\//g, "-");
          update_time = update_time.replace("24", "00");

          if (!table.length || table[table.length - 1]['update_time'] !== day) {
            table.push({
              id: id_count++,
              update_time: day,
              file_name: "#",
              children: [{
                id: id_count++,
                update_time: day + " " + update_time,
                file_name: file
              }]
            });
          } else {
            table[table.length - 1]['children'].unshift({
              id: id_count++,
              update_time: day + " " + update_time,
              file_name: file
            });
          }
        }

        that.info.table = table;
        that.info.ok = true;
      }).catch(error => {
        that.info.err = true;
        that.info.err_msg = error;
        console.log(error);
      });
    },

    async fetch_history() {
      this.loading_icon = true;
      let url = this.data_url;
      this.fetch_data(url, null, true).then(async data => {
        // 增加历史数据翻页后，若频繁翻页可能会让提示框显示多次，先移除之
        // let msg = "历史数据加载成功"
        // ElNotification.success({
        //   message: msg,
        //   duration: 1200,
        //   position: 'bottom-right',
        //   showClose: false,
        //   customClass: 'notification-item',
        // })
        this.raw = data;
        this.high_init().then();
        this.middle_init().then();
        this.low_init().then();
        this.ok = true;
        this.loading_icon = false;
        setTimeout(() => {
          this.filter_text = localStorage.getItem("filter_text") || "";
          localStorage.setItem("filter_text", '');
        }, 0);
        localforage.setItem(`${this.data_name}_timestamp`, new Date().getTime()).then();
      }).catch(error => {
        console.log(error);
        let msg = "历史数据加载失败";
        ElNotification.info({
          message: msg,
          duration: 2500,
          position: 'bottom-right',
          showClose: false,
          customClass: 'notification-item'
        });
        this.err_msg = error;
        this.err = true;
        this.loading_icon = false;
      });
    },

    list2tree(list, data) {
      let tree = [];
      let id_count = 0;
      let province_id_list = [];
      let city_id_list = [];
      let county_id_list = [];

      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let province = item["province"];
        let city = item["city"];
        let county = item["county"];
        let communitys = item["communitys"];
        let province_item = null;
        let city_item = null;
        let county_item = null;
        let communitys_item = null;

        for (let j = 0; j < tree.length; j++) {
          let tree_item = tree[j];

          if (tree_item.label === province) {
            province_item = tree_item;
            break;
          }
        }

        if (province_item === null) {
          province_item = {
            id: id_count++,
            count: 0,
            pid: -1,
            label: province,
            children: [],
            expanded: true
          };
          tree.push(province_item);
          province_id_list.push(province_item.id);
        }

        for (let j = 0; j < province_item.children.length; j++) {
          let province_item_child = province_item.children[j];

          if (province_item_child.label === city) {
            city_item = province_item_child;
            break;
          }
        }

        if (city_item === null) {
          city_item = {
            id: id_count++,
            count: 0,
            pid: province_item.id,
            label: city,
            children: [],
            expanded: true
          };
          province_item.children.push(city_item);
          city_id_list.push(city_item.id);
        }

        for (let j = 0; j < city_item.children.length; j++) {
          let city_item_child = city_item.children[j];

          if (city_item_child.label === county) {
            county_item = city_item_child;
            break;
          }
        }

        if (county_item === null) {
          county_item = {
            id: id_count++,
            count: 0,
            pid: city_item.id,
            label: county,
            children: [],
            expanded: true
          };
          city_item.children.push(county_item);
          county_id_list.push(county_item.id);
        }

        for (let j = 0; j < county_item.children.length; j++) {
          let county_item_child = county_item.children[j];

          if (county_item_child.label === communitys) {
            communitys_item = county_item_child;
            break;
          }
        }

        if (communitys_item === null) {
          communitys_item = [];

          for (let j = 0; j < communitys.length; j++) {
            province_item.count++;
            city_item.count++;
            county_item.count++;
            county_item.children.push({
              id: id_count++,
              pid: county_item.id,
              label: communitys[j]
            });
          }
        }
      }

      data.display = !!tree.length;
      data.tree = tree;
      data.province_id_list = province_id_list;
      data.city_id_list = city_id_list;
      data.county_id_list = county_id_list; // data.default_id_list = city_id_list.length < 15 ? province_id_list.concat(county_id_list) : county_id_list;

      data.default_id_list = city_id_list.length < 15 ? province_id_list : null;
    },

    async high_init(data_name = null) {
      if (!this.raw.data["hcount"]) {
        this.high.display = false;
        return;
      }

      if (!data_name) {
        this.list2tree(this.raw.data["highlist"], this.high);
        this.high.count = this.raw.data["hcount"];
      } else {
        const high = await localforage.getItem(`${data_name}_high`);

        if (!high || new Date().getTime() - (await localforage.getItem(`${data_name}_timestamp`)) > 5 * 60 * 1000) {
          this.list2tree(this.raw.data["highlist"], this.high);
          this.high.count = this.raw.data["hcount"];
          localforage.setItem(`${data_name}_high`, JSON.parse(JSON.stringify(this.high))).then();
        } else {
          this.high = high;
        }
      }

      this.high.key++;
    },

    async middle_init(data_name = null) {
      if (!this.raw.data["mcount"]) {
        this.middle.display = false;
        return;
      }

      if (!data_name) {
        this.list2tree(this.raw.data["middlelist"], this.middle);
        this.middle.count = this.raw.data["mcount"];
      } else {
        const middle = await localforage.getItem(`${data_name}_middle`);

        if (!middle || new Date().getTime() - (await localforage.getItem(`${data_name}_timestamp`)) > 5 * 60 * 1000) {
          this.list2tree(this.raw.data["middlelist"], this.middle);
          this.middle.count = this.raw.data["mcount"];
          localforage.setItem(`${data_name}_middle`, JSON.parse(JSON.stringify(this.middle))).then();
        } else {
          this.middle = middle;
        }
      }

      this.middle.key++;
    },

    async low_init(data_name = null) {
      if (!this.raw.data["lcount"]) {
        this.low.display = false;
        return;
      }

      if (!data_name) {
        this.list2tree(this.raw.data["lowlist"], this.low);
        this.low.count = this.raw.data["lcount"];
      } else {
        const low = await localforage.getItem(`${data_name}_low`);

        if (!low || new Date().getTime() - (await localforage.getItem(`${data_name}_timestamp`)) > 5 * 60 * 1000) {
          this.list2tree(this.raw.data["lowlist"], this.low);
          this.low.count = this.raw.data["lcount"];
          localforage.setItem(`${data_name}_low`, JSON.parse(JSON.stringify(this.low))).then();
        } else {
          this.low = low;
        }
      }

      this.low.display = true;
      this.low.empty_text = "无数据";
      this.low.key++;
    },

    render_tree(h, {
      node
    }) {
      return h('span', {
        class: 'el-tree-node__label'
      }, h('span', null, `${node.label}`), h('span', {
        style: 'font-size: 0.87rem;'
      }, (() => {
        if (node.data.expanded) {
          return ` (${node.data.count})`;
        }
      })()));
    },

    high_expand() {
      this.high.expand_all = !this.high.expand_all;

      if (this.high.expand_all) {
        let id_list = this.high.province_id_list.concat(this.high.city_id_list).concat(this.high.county_id_list);

        for (let i = 0; i < id_list.length; i++) {
          this.$refs.high_tree.store.getNode(id_list[i]).expanded = true;
        }
      } else {
        let id_list = this.high.city_id_list.length < 15 ? this.high.city_id_list : this.high.province_id_list;

        for (let i = 0; i < id_list.length; i++) {
          this.$refs.high_tree.store.getNode(id_list[i]).expanded = false;
        }
      }

      this.high.expand_all_button = this.high.expand_all ? "收起" : "展开";
    },

    middle_expand() {
      this.middle.expand_all = !this.middle.expand_all;

      if (this.middle.expand_all) {
        let id_list = this.middle.province_id_list.concat(this.middle.city_id_list).concat(this.middle.county_id_list);

        for (let i = 0; i < id_list.length; i++) {
          this.$refs.middle_tree.store.getNode(id_list[i]).expanded = true;
        }
      } else {
        let id_list = this.middle.city_id_list.length < 15 ? this.middle.city_id_list : this.middle.province_id_list;

        for (let i = 0; i < id_list.length; i++) {
          this.$refs.middle_tree.store.getNode(id_list[i]).expanded = false;
        }
      }

      this.middle.expand_all_button = this.middle.expand_all ? "收起" : "展开";
    },

    low_expand() {
      this.low.expand_all = !this.low.expand_all;

      if (this.low.expand_all) {
        let id_list = this.low.province_id_list.concat(this.low.city_id_list).concat(this.low.county_id_list);

        for (let i = 0; i < id_list.length; i++) {
          this.$refs.low_tree.store.getNode(id_list[i]).expanded = true;
        }
      } else {
        let id_list = this.low.city_id_list.length < 15 ? this.low.city_id_list : this.low.province_id_list;

        for (let i = 0; i < id_list.length; i++) {
          this.$refs.low_tree.store.getNode(id_list[i]).expanded = false;
        }
      }

      this.low.expand_all_button = this.low.expand_all ? "收起" : "展开";
    },

    high_filter(value, data) {
      if (!value) {
        if (data.children === undefined) {
          this.high.count++;
        }

        return true;
      }

      let value_list = value.split(" ");

      for (let i = 0; i < value_list.length; i++) {
        let value = value_list[i];

        if (!value) {
          continue;
        }

        if (data.label.includes(value)) {
          if (data.children === undefined) {
            this.high.count++;
          }

          return true;
        }

        let parent_id = data.pid;

        while (parent_id !== -1) {
          let parent_node = this.$refs.high_tree.store.getNode(parent_id).data;

          if (parent_node.label.includes(value)) {
            if (data.children === undefined) {
              this.high.count++;
            }

            return true;
          }

          parent_id = parent_node.pid;
        }
      }

      return false;
    },

    middle_filter(value, data) {
      if (!value) {
        if (data.children === undefined) {
          this.middle.count++;
        }

        return true;
      }

      let value_list = value.split(" ");

      for (let i = 0; i < value_list.length; i++) {
        let value = value_list[i];

        if (!value) {
          continue;
        }

        if (data.label.includes(value)) {
          if (data.children === undefined) {
            this.middle.count++;
          }

          return true;
        }

        let parent_id = data.pid;

        while (parent_id !== -1) {
          let parent_node = this.$refs.middle_tree.store.getNode(parent_id).data;

          if (parent_node.label.includes(value)) {
            if (data.children === undefined) {
              this.middle.count++;
            }

            return true;
          }

          parent_id = parent_node.pid;
        }
      }

      return false;
    },

    low_filter(value, data) {
      if (!value) {
        if (data.children === undefined) {
          this.low.count++;
        }

        return true;
      }

      let value_list = value.split(" ");

      for (let i = 0; i < value_list.length; i++) {
        let value = value_list[i];

        if (!value) {
          continue;
        }

        if (data.label.includes(value)) {
          if (data.children === undefined) {
            this.low.count++;
          }

          return true;
        }

        let parent_id = data.pid;

        while (parent_id !== -1) {
          let parent_node = this.$refs.low_tree.store.getNode(parent_id).data;

          if (parent_node.label.includes(value)) {
            if (data.children === undefined) {
              this.low.count++;
            }

            return true;
          }

          parent_id = parent_node.pid;
        }
      }

      return false;
    },

    tag_add(item) {
      item = item.trim();

      if (item === "") {
        return;
      }

      while (item.includes("  ")) {
        item = item.replace("  ", " ");
      }

      this.filter_history.unshift(item);
      this.filter_history = Array.from(new Set(this.filter_history));
      localStorage.setItem("filter_history", JSON.stringify(this.filter_history));
    },

    tag_remove(index) {
      this.filter_history.splice(index, 1);
      localStorage.setItem("filter_history", JSON.stringify(this.filter_history));
    },

    router_to(path) {
      if (!path) {
        return;
      }

      this.$router.push({
        path: "./" + path
      });
    },

    info_click(row) {
      if (row.file_name === "#") {
        this.$refs.info_table.toggleRowExpansion(row);
      } else {
        this.router_to(row.file_name);
      }
    },

    get_current_history_info(file_name) {
      const info_list = this.info.raw['file_list'];
      let index = info_list.findIndex(item => item.file_name === file_name + ".json");

      if (index === -1) {
        return;
      }

      const pre_index = index - 1;
      const next_index = index + 1;
      const pre = pre_index < 0 ? null : info_list[pre_index]['file_name'].replace(".json", "");
      const next = next_index >= info_list.length ? null : info_list[next_index]['file_name'].replace(".json", "");
      const save_time = new Date(info_list[index]['save_time'] * 1000).toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, "-");
      return {
        pre: pre,
        next: next,
        save_time: save_time
      };
    },

    download_data(target) {
      const down_url = 'https://github.com/panghaibin/RiskLevelAPI/archive/refs/heads/api.zip';

      if (target === -1) {
        window.open(down_url);
      } else if (target >= 0 && target < this.gh_proxy_list.length) {
        window.open(this.gh_proxy_list[target] + down_url);
      }
    },

    scroll_to(hash) {
      this.$router.push({
        path: "",
        hash: hash,
        replace: true
      });
      const offset = !hash ? 0 : document.querySelectorAll(hash)[0].offsetTop - 55;
      this.is_scrolling = true;
      const fixedOffset = offset.toFixed();

      const onScroll = () => {
        if (window.pageYOffset.toFixed() === fixedOffset) {
          window.removeEventListener('scroll', onScroll);
          this.is_scrolling = false;
        }
      };

      window.addEventListener('scroll', onScroll);
      onScroll();
      window.scrollTo({
        left: 0,
        top: offset,
        behavior: 'smooth'
      });
    },

    handle_tabs_click(tab) {
      this.scroll_to(tab.props.name);
    },

    back_to_top() {
      this.scroll_to('');
      this.selected_tab = '';
    },

    handle_color_scheme_change(e) {
      this.dark_mode = e.matches;
    },

    observe_sections() {
      try {
        this.section_observer.disconnect(); // eslint-disable-next-line no-empty
      } catch (e) {}

      const options = {
        rootMargin: '-15% 0px -85%',
        threshold: 0
      };
      this.section_observer = new IntersectionObserver(debounce(entries => {
        this.section_observer_handler(entries);
      }, 10), options);
      const sections = document.querySelectorAll(this.section_selector);
      sections.forEach(section => {
        this.section_observer.observe(section);
      });
    },

    section_observer_handler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting && !this.is_scrolling) {
          const section_id = entry.target.id;
          const hash = section_id === '' ? '' : '#' + section_id;
          this.selected_tab = hash;
          this.$router.push({
            path: "",
            hash: hash,
            replace: true
          });
        }
      }
    }

  },
  watch: {
    filter_text(value) {
      value = value.trim();

      while (value.includes("  ")) {
        value = value.replace("  ", " ");
      }

      if (this.high.display) {
        this.high.count = 0;
        this.$refs.high_tree.filter(value);

        if (value) {
          this.high.expand_all = true;
          this.high.expand_all_button = '收起';
        }
      }

      if (this.middle.display) {
        this.middle.count = 0;
        this.$refs.middle_tree.filter(value);

        if (value) {
          this.middle.expand_all = true;
          this.middle.expand_all_button = '收起';
        }
      }

      if (this.low.display) {
        this.low.count = 0;
        this.$refs.low_tree.filter(value);

        if (value) {
          this.low.expand_all = true;
          this.low.expand_all_button = '收起';
        }
      }
    },

    is_scrolling(value) {
      if (value) {
        setTimeout(() => {
          this.is_scrolling = false;
        }, 1000);
      }
    }

  },

  beforeUnmount() {
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.handle_color_scheme_change);
    this.section_observer.disconnect();
    this.raw = null;
    this.high = null;
    this.middle = null;
    this.low = null;
    this.filter_history = null;
    localStorage.setItem('filter_text', this.filter_text);
    this.filter_text = null;
  }

};