import RegionShow from "@/components/RegionShow";
import FooterShow from "@/components/FooterShow";
import { Head } from '@vueuse/head';
import update from '@/mixins/update';
const gh_api = process.env.VUE_APP_GH_API;

const gh_proxy_list = (() => {
  const gh_list = JSON.parse(process.env.VUE_APP_GH_PROXY_LIST).map(url => {
    return `https://${url}/`;
  });
  const arr = gh_list.slice(2, gh_list.length);

  for (let i = 1; i < arr.length; i++) {
    const random = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[random]] = [arr[random], arr[i]];
  }

  return gh_list.slice(0, 2).concat(arr);
})();

const api_url_list = [gh_api].concat(JSON.parse(process.env.VUE_APP_API_URL_LIST)).concat(gh_proxy_list.map(item => {
  return `${item}${gh_api}`;
}));
localStorage.setItem('api_url_list', JSON.stringify(api_url_list));
localStorage.setItem('gh_proxy_list', JSON.stringify(gh_proxy_list));
export default {
  name: "HomeView",
  components: {
    RegionShow,
    FooterShow,
    Head
  },
  computed: {
    api_url() {
      let file_name = this.$route.params.fileName;

      if (file_name) {
        return file_name + '.json';
      } else {
        return 'latest.json';
      }
    },

    type_latest() {
      return !this.$route.params.fileName;
    }

  },

  data() {
    return {
      title: "全国新冠疫情风险地区查询",
      info_url: 'info.json'
    };
  },

  watch: {
    api_url() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }

  },
  mixins: [update]
};